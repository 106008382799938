@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'ITC Officina Sans Std';
  src: url('/fonts/OfficinaSansStd-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Officina Sans Std';
  src: url('/fonts/OfficinaSansStd-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/GothamLight.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
}

button{
  font-family: Gotham;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

main {
  padding: 16px;
}

.row {
  display: flex;
  flex-direction: row;
}

.mapboxgl-map {
  font-family: Gotham, ITC Officina Sans Std, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
}

.mapboxgl-popup-content {
  padding: 0 !important;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-ctrl-geocoder--input {
  font-size: 14px !important;
  padding: 6px 35px !important;
  height: 36px !important;
}

.mapboxgl-ctrl-geocoder--icon-search {
  left: 7px !important;
  top: 10px !important;
  width: 20px !important;
  height: 20px !important;
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 16px !important;
  height: 16px !important;
  margin-top: 3px !important;
  margin-right: 0 !important;

}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 24px 10px 0 0 !important;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  padding: 4px !important;
  border-radius: 12px !important;
}

.mapboxgl-ctrl button .mapboxgl-ctrl-icon {
  width: 24px !important;
  height: 24px !important;
  margin: 0 auto !important;
}

.mapboxgl-ctrl-group button + button {
  border-top: 1px solid #F3F4F6 !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDE3TDE5IDE5TDEwIDFMMSAxOUwxMCAxN1pNMTAgMTdMMTAgOSIgc3Ryb2tlPSIjM0Q0MjREIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMUw3IDdNNyA3TDcgMTNNNyA3TDEzIDdNNyA3TDEgNyIgc3Ryb2tlPSIjM0Q0MjREIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) !important
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDEyTDYgMTIiIHN0cm9rZT0iIzNENDI0RCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==) !important
}

.jw-state-idle .jw-controls, .jw-controls-backdrop {
  background: transparent !important;
}
